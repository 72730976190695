.row{display: flex !important; justify-content: center;}
.card{position: relative;box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
     border-radius: 15px; cursor: pointer; display: flex; align-items: flex-start; width: 100%; height:240px; justify-content: center; overflow: hidden;}
.card img{width: 100%; transform: scale(1,1); transition: 0.3s;}
.col{padding:10px;}
.card_text{position: absolute; bottom:0px; left:0px; width:100%; height: 60px; display: flex !important; align-items: center !important;padding:10px;
background-color:#273a7a98;  backdrop-filter: blur(1px);box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}
.card_text h1{color: white;  font-size: 20px; line-height: 20px; margin-bottom: 0px; }
.card:hover img{transform: scale(1.1, 1.1);}
.modalTitle{margin-bottom: 0px;}
