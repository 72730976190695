.id{width:5%; 
    background-color: white; 
    text-align:center;}
.address {
    width: 20% !important;
    text-align: center;
    height: 90px;
    padding:0px !important;
}
.bir {
    
    height: 90px !important;
    width:100%;
    text-align: center !important;
    
    display: flex !important; 
    align-items: center !important;
    justify-content: center;
    z-index: -15 !important;
    margin-bottom: 0px !important;
    
}
.ikki {
    margin-bottom: 0px !important;

    display: none !important;
    text-align: center;
    align-items: center !important;
    justify-content: center;
    
    height: 90px !important;
    padding:0px !important;
    color: black;

    width: 100%;

 
}

.address:hover .bir {
    display: none !important;
   
}

.address:hover .ikki {
    display: flex !important;
    justify-content: center;
    background-color: white; 

    align-items: center !important;
    z-index: -15 !important;
}
.person {
    width: 30%;
    overflow-x: hidden;
    text-align: center;
    background-color: white; 
}

.phone {
    width: 15%;
    overflow-x: hidden;
    text-align: center;
   background-color: white; 
}

.email {
    text-align: center;
    background-color: white; 
    width: 20%;
    overflow-x: hidden;
  
}
.name {
    text-align: center;
    background-color: white; 
    width: 30%;
    overflow-x: hidden;
   
}
.tab tr:hover {
    background-color: rgb(218, 240, 253);
    color: black;
}
.tab tr:hover td{border-color:rgb(218, 240, 253)}

.lo{transform: rotate(45deg); position: absolute; background:linear-gradient(rgba(255, 255, 255, 0.192), rgba(255, 255, 255, 0.425),rgba(255, 255, 255, 0.685),rgba(255, 255, 255, 0.425), rgba(255, 255, 255, 0.192)) ;
     height:20px; width:200px;}
/* 
@media (max-width:768px){

    .tab{width:1000px !important}
    .the{width:1000px !important}
    .person {
        width: 250px !important;
        
    }
    
    .phone {
        width: 150px !important;
       
    }
    
    .email {
        
        width: 150px !important;
        }
    .name {
         width: 190px !important;
        }
} */

.loader{position: fixed !important; top:0px !important; left:0px !important;  width:100vw !important; height:100vh !important; z-index: 5678 !important;   background: #123065d0 !important;
    backdrop-filter: saturate(180%) blur(10px) !important; display:flex !important; align-items: center !important; justify-content: center !important;}
  .loader img{width:100% !important;box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset !important; border-radius:50% !important; animation-name:logo !important; animation-duration: 2s !important;animation-iteration-count: infinite !important; }
  
  .bef{width:300px !important; border-radius:50% !important; padding:2px !important;}
  .headAcc{display: flex; width:100%; z-index: 2 !important;}
  .headAcc div{padding:5px; height:35px; font-size: 16px;}
  .headId{width:5% !important}
  .headName{width:30% !important; text-transform: uppercase !important; font-size: 16px !important; overflow-y: hidden;}
  .headPerson{width:30% !important;  padding-left: 0px !important; text-align: center;}
  .headPhone{width:15% !important;  text-align: center;font-size: 16px !important;}
  .headEmail{width:20% !important;  overflow-x: hidden; text-align: center; padding-left: 0px !important;}
  .Add{font-size: 16px;  text-align: center;}
 .ant-collapse-header{ display: flex; align-items: center;} 
.ulTab{margin-top:-20px !important}
.op{width: 100%; overflow-x: auto; overflow-y: hidden;}
@media(max-width:1600px){
  
    .headId{ width:5% !important}
    .headName{ display: flex; align-items: center; margin-top: -10px ; height:50px !important; width:30% !important; text-transform: uppercase !important;  overflow-y: hidden;}
    .headPerson{  display: flex; align-items: center; justify-content: center; margin-top: -10px ; overflow-y: hidden; height:50px !important; width:30% !important;  padding-left: 20px !important; text-align: center;}
    .headPhone{ width:15% !important;  text-align: center;}
    .headEmail{ width:20% !important;  text-align: center; padding-left: 10px !important;}
    .id{width:5% !important}
    .name{width:30% !important; text-transform: uppercase !important; font-size: 16px !important; overflow-y: hidden;}
    .person{width:30% !important;  padding-left: 20px !important; text-align: center;}
    .phone{width:15% !important;  text-align: center;}
    .email{width:20% !important;  text-align: center; padding-left: 0px !important;}
}
@media (max-width:1024px){
    .headName{padding-left: 42px !important; width:100% !important;text-align: justify !important;}
}
@media (max-width:768px){
    .headName{padding-left: 22px !important;}
}