.app_nav {
  padding: 10px 0;
  background-color: #fff;

  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 333 !important;
  .downicon {
    cursor: pointer;
    @media (max-width: 900px) {
      display: none;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
  }

  &-info {
    display: flex;
    align-items: center;
    max-width: 385px;
    width: 100%;
    gap: 10px;
    line-height: 22px;
    text-align: left;
    @media (max-width: 1200px) {
      p {
        font-size: 13px !important;
      }
    }
  
  }

  &-logo {
    min-width: 30px;
    height: 80px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &-links {
    @media (max-width: 900px) {
      position: fixed;
      width: 100vw;
      height: 100vh;
      background-color: #fff;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow-y: auto;
      z-index: 1;
      transform: translateY(-100%);
      transition: 0.3s;
      &.open {
        transform: translateY(0);
      }
    }
  }

  &-sub_list {
    transition: 0.3s;
    padding-top: 20px;

    @media (min-width: 900px) {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateY(20px) translateX(-50%);
      opacity: 0;
      pointer-events: none;
      visibility: hidden;
      min-width: 250px;
      &:before {
        content: "";
        position: absolute;
        width: 20px;
        height: 20px;
        background-color: #FFFFFF;
        top: 10px;
        left: 50%;
      
        border: 1px solid #dedede;
        transform: translateX(-50%) rotate(45deg);
      }
    }

    &-item {
      background-color: #fff;
      border: 1px solid #dedede;
      position: relative;

      &:not(:last-child) {
        border-bottom: none;

        &::before {
          content: "";
          position: absolute;
          width: 80%;
          height: 1px;
          background-color: #dedede;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
        }
      }

      &:not(:first-child) {
        border-top: none;
      }

      &:first-child {
        border-radius: 8px 8px 0 0;
      }

      &:last-child {
        border-radius: 0 0 8px 8px;
      }
    }

    &-link {
      color:grey;
      display: block;
      padding: 10px 10%;
      z-index: 3;
      text-transform: uppercase;
      position: relative;
    }
  }
  .chiz1{height:1px; background-color: #dedede; z-index: 45; width:100% !important; position: fixed; top:20px; left:0;}

  .chiz{height:1px; background-color: white; z-index: 456; width:28px !important; position: fixed; top:20px; left:calc(50% - 14px);}
  .app_nav-sub_list {
   margin-left:-10%;
   margin-top: -15px;
   z-index: 4344 !important;
    @media (max-width: 900px) {
      margin-left:0px;
      max-height: 0;
      padding: 0;
      overflow: hidden;
      width: 100%;
    }
  }

  &-list {
    display: flex;
    gap: 20px;
    @media (max-width: 900px) {
      flex-direction: column;
      text-align: center;
      font-size: 30px !important;
    }
    &_item{
      padding-top: 30px;
    }
    &_item:hover {
      @media (min-width: 900px) {
        .app_nav-sub_list {
          opacity: 1;
          visibility: visible;
       
          transform: translateY(0) translateX(-50%) !important;
          pointer-events: all;
        }
      }
      @media (max-width: 900px) {
        .app_nav-sub_list {
          padding: 10px;
          max-height: 1000px;
          height: 100%;
          overflow: visible;
          font-size: 20px !important;
        }
      }
    }

    &_item.with_sublist {
      position: relative;
      padding-top:30px;
      
      @media (min-width: 900px) {
        &:after {
          // content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 10px 7px 0 7px;
          border-color: #000 transparent transparent transparent;
        }
        display: flex;
        align-items: center;
        gap: 5px;
      }

      .app_nav-list_link:active {
        pointer-events: none;
      }
    }

    &_link,
    .app_nav-sub_list-link {
      color: rgb(162, 160, 160);
      font-weight: 600;
      position: relative;
      text-transform: uppercase;
      transition: 0.3s;
      white-space: nowrap;

      &:not(.app_nav-sub_list-link):before {
        content: "";
        height: 3px;
        width: 100%;
        position: absolute;
        left: 50%;
        bottom: -4px;
        transform: translateX(-50%) scaleX(0);
        transition: 0.3s;
        background-color: #133165;
      }

      &:hover {
        color: #133165;

        &:before {
          transform: translateX(-50%) scaleX(1);
        }
      }
    }
  }

  &-close_btn {
    position: absolute;
    top: 30px;
    right: 20px;
  }

  &-burger_btn,
  &-close_btn {
    display: none;
    min-width: 40px;
    height: 40px;
    cursor: pointer;
    @media (max-width: 900px) {
      display: flex;
    }
  }

  .app_lang-switcher {
    @media (max-width: 900px) {
      display: none;
    }
  }

  .menu_lang-switcher {
    display: none;
    @media (max-width: 900px) {
      display: flex;
      position: absolute;
      top: 30px;
      left: 20px;
      transform: scale(1.3);
    }
  }

  @media (max-width: 900px) {
    font-size: 70% !important;
  }
}

@media (min-width: 900px) {
  .app_nav-list {
    flex-wrap: wrap;
    justify-content: center;
  }
 
}

@media (max-width: 675px) and (max-width: 900px){
  .app_nav-links.open {
    padding: 50px 0;
    overflow: hidden;
  }
  .app_nav-list_item p{margin-bottom: 0px !important;}
  .app_nav-list {
   height: 100vh !important;
   padding-top: 15vh !important;
    overflow: auto;
  }
  .app_nav-list_item{padding-top: 10px !important;}
}
.active a{color:#133165 !important;}
.app_nav-info p{position: relative; top:8px;}
.app_nav-sub_list-link:hover{color:#133165 !important}

.n3__vision__action-button:nth-child(1){position: relative; top:-20px !important}
// .butToza{position: fixed; top:300px; left:40px; z-index: 345 !important;}
.n3__vision__modal__dialog-wrapper{color: transparent !important; }
// .ant-image-preview-img, .ant-image-preview-body,.ant-image-preview-operation{z-index: 4444 !important;}
.app_lang-switcher svg{position: relative; top:2px}
.app_nav-sub_list{z-index: 4445 !important;}
.lenta{background-color: rgba(0, 157, 255, 0.787); padding: 3px 0; color: white; margin-top: -10px; position: relative; display: flex; height: 35px; align-items: center; margin-bottom: 10px;}
.lenta p{font-size: 17px; font-weight: 500; text-transform: uppercase; display: flex; margin-bottom: 0px; position: absolute; white-space: nowrap; width: 200vw; justify-content: space-between;}
.lenta_text_1{animation-name: lenta_1; animation-timing-function: ease-in; animation-duration: 50s; animation-iteration-count: infinite; display: flex;}
@keyframes lenta_1{
  0%{left: 100%; display:flex;}
  99%{left:-200%; display: none;} 
 100%{left: 100%; display: none;} 

}
@media (max-width: 800px){
.lenta p{width: 1600px;}
.lenta_text_1{animation-duration: 30s;}

@keyframes lenta_1{
  0%{left: 100%; display:flex;}
  99%{left:-1600px; display: none;} 
 100%{left: 100%; display: none;} 

}
}
@media (max-width: 470px){
  .app_nav-info{gap:10px}
  .app_nav-logo{width:60px !important; height:60px !important;}
  .app_nav .container{gap:10px; padding:0px 5px}
  .app_nav-info p{width:calc(100% - 60px) !important}

}
