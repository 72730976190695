.modal {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  transform: translateY(-130%);
  transition: 0.3s;
  &.show {
    transform: translate(0);
  }

  &_content {
    max-width: 450px;
    width: 100%;
    padding: 36px 45px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -70%);
    &-body {
      max-height: 70vh;
      overflow: auto;
    }
  }

  &_overlay {
    // background-color: rgba(0,0,0,0.8);
    height: 100%;
    width: 100%;
  }

  &_close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    background: #E5E5E5;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center
  }
  .contact-btn{
    background: none;
  }

}