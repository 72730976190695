.activity {
    padding: 100px 0;
    &_list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        row-gap: 20px;
        column-gap: 15px;
        width: 100%;
        
        @media (max-width: 1125px) {
            grid-template-columns: repeat(2, 1fr);   
        }
        @media (max-width: 660px) {
            grid-template-columns: repeat(1, 1fr); 
            width: 80%;
            margin: 0 auto;
        }
        @media (max-width: 522px) {
            width: 100%;
            
        }
        &-item {
            padding: 20px 20px 0px 20px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            border-radius: 5px;
           
            max-width: 515px;
            width: 100%;
            
            @media (max-width: 1090px) {
                width: auto;
                flex: 1 1;
                min-width: 300px;
            }
            &_img {
                width: 100%;
                height: 300px;
                margin-bottom: 15px;
                border-radius: 5px;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    transition: 0.3s;
                }
            }
            &_link {
                // height:80px !important;
                // display:flex;
                // align-items: center;
                color: #a5a3a3;
                font-size: 15px;
                line-height: 20px;
                font-weight: 600;
            }
            &:hover {
                .activity_list-item_img img {
                    transform: scale(1.2);
                }
                .activity_list-item_link {
                    color: #133165;
                }
            }
        }
    }
}
.video_box{width: 100%; display: flex; justify-content: center;}
.video_item{width: 75%;}
.video_item video{width: 100%; border-radius: 20px; overflow: hidden;}
.video_div{margin-top: 100px;}
.audio{width: 100%; margin-top: 20px;}
.audio audio{width: 100%;}
audio::-webkit-media-controls-play-button,
     audio::-webkit-media-controls-panel {
     background-color: white;
     
     }
     audio{box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px; border-radius: 20px;}
     .audio p{font-size: 16px; text-align: right;}
@media (max-width:800px){
    .video_item{width: 90%;} 
}
