.hamkorlik {
  padding: 70px 0;
  .containerr {
    width: 70%;
    margin: 0 auto;
    @media (max-width: 1200px) {
      width: 100%;
      p {
        font-size: 16px;
      }
    }
  }
  &-info {
    .img {
      background-image: url("./photo1.jpg");
      width: 100%;
      height: 60vh;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    p {
      padding-top: 20px;
      font-size: 18px;
      display: block;
      text-align: justify;
    }
  }
  &-foreign{
    text-align: center;
    .section{
      padding-top: 110px;
      p{
        font-weight: 700;
        font-size: 18px;
        padding-top: 10px;
      }
      .img{
        width: 190px;
      }
    }
  }
}
