.callcenter{
    padding: 70px 0;
    .containerr{
        width: 70%;
        margin: 0 auto;
        display: flex; 
    flex-direction: column;
    align-items: center !important;
    justify-content: center;
        @media (max-width: 1200px) {
            width: 100%;
            padding-bottom: 100px;
            p{
                font-size: 16px;
            }
        }
    }
    
    &-img{
        background-image: url("./call_center.svg");
        width: 80%;
        height: 55vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
    }
    &-text{        
        p{
            padding-top: 20px;
            font-size: 18px;
            display: block;
            text-align: justify;
        }
    }

}
.call_center_item{display: flex; font-size: 22px; margin: 20px 0px; color: #133165; width: 100%; justify-content: center; align-items: center;}
.call_center_item img{width: 35px; margin-right: 10px;}