.partners {
    z-index: 1111;
    padding-bottom: 70px;
    padding-top: 70px;
    user-select: none;
    a {
        color: #333333;
    }
    .swiper-wrapper {
        padding: 30px 0;
    }
    .contanerr {
        max-width: 90%;
        margin: 0 auto;
    }
    .swiper {
        padding: 0 54px;
        .swiper-button-next {
            display: none;
        }
        .swiper-button-prev {
            display: none;
        }
    }
    .slider-item_img {
        margin-bottom: 20px;
        -webkit-user-drag: none;
        img {
            box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
            border-radius:50% ;
            width: 145px;
            height: 145px;
        }
    }
    .swiper-slide_body {
        text-align: center;
        padding: 20px;
        // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 10px;
        p{font-size: 18px;}
    }
}
.swiper-slide_body img{transition: 0.3s;}
.swiper-slide_body:hover img{transform: translate(0px, -10px);}
.partNew{color:#a4a2a2; transition: 0.3s; text-transform: uppercase;}
.swiper-slide_body:hover .partNew{color:#133165 ;}

