.hujjatlar{
    padding: 70px 0;
    .containerr{
        width: 70%;
        margin: 0 auto;
        @media (max-width: 1200px) {
            width: 100%;
            p{
                font-size: 16px;
            }
        }
        .rule-text{
            text-align: center !important;
        }
    }
    &-title{
        padding-bottom: 20px;
        font-size: 30px;
        font-weight: 900;
        text-align: center;
    }
    &-rule{
        text-align: center;
        font-size: 20px;
    }
    &-umumiy{
        P{
            font-size: 19px;
            padding-top: 20px;
            text-align: justify;
        }       
    }
    &-downloader{ 
        padding-top: 30px;
        a{
            display: block;
            color: #133165;
            text-decoration: none;
            font-size: 20px;
            font-weight: 600;
            padding: 5px 0;
        }
    }
}