.markaziy{
    padding: 70px 0;
    .containerr{
        width: 70%;
        margin: 0 auto;
        @media (max-width: 1200px) {
            width: 100%;
            p{
                font-size: 16px;
            }
        }
    }
    &-img{
        background-image: url("./mk-bino.png");
        width: 100%;
        height: 60vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top center;
    }
    &-text{        
        p{
            padding-top: 20px;
            margin-bottom: 0px;
            font-size: 18px;
            display: block;
            text-align: justify;
        }
    }

}