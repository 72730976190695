* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

button {
  cursor: pointer;

}

a {
  text-decoration: none;
}

ol, ul, li {
  list-style: none;
}


html {
  overflow-x: hidden;
  font-family: "Roboto Condensed", sans-serif !important;
  color: #333333;
}
*{
  font-family: "Roboto Condensed", sans-serif !important;
}
html, body {
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.wrapper {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  position: relative;

}

.main {
  flex: 1 1;
}

.container {
  max-width: 1640px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
}

body {
  &.n3__wcag__color_black *:not(.n3__vision__settings-panel):not(.n3__vision__settings-panel__groups):not(.n3__vision__settings-group):not(.n3__vision__settings-group__label):not(.n3__vision__modal__dialog-wrapper):not(.n3__vision__modal__backdrop):not(.n3__vision__settings-button):not(.n3__vision__settings-group__buttons):not(.n3__vision__color-button):not(.n3__vision__settings-panel__buttons):not(.n3__vision__modal) {
    background-color: #000000 !important;
    color: #ffffff !important;
    box-shadow: none !important;
  }

  &.n3__wcag__color_white *:not(.n3__vision__settings-panel):not(.n3__vision__settings-panel__groups):not(.n3__vision__settings-group):not(.n3__vision__settings-group__label):not(.n3__vision__modal__dialog-wrapper):not(.n3__vision__modal__backdrop):not(.n3__vision__settings-button):not(.n3__vision__settings-group__buttons):not(.n3__vision__color-button):not(.n3__vision__settings-panel__buttons):not(.n3__vision__modal) {
    background-color: #fff !important;
    color: #000 !important;
    box-shadow: none !important;
  }

  &.n3__wcag__color_blue *:not(.n3__vision__settings-panel):not(.n3__vision__settings-panel__groups):not(.n3__vision__settings-group):not(.n3__vision__settings-group__label):not(.n3__vision__modal__dialog-wrapper):not(.n3__vision__modal__backdrop):not(.n3__vision__settings-button):not(.n3__vision__settings-group__buttons):not(.n3__vision__color-button):not(.n3__vision__settings-panel__buttons):not(.n3__vision__modal) {
    background-color: #9DD0FC !important;
    color: #000 !important;
    box-shadow: none !important;
  }

  &.n3__wcag__images_off  img:not(.lang-item) {
    filter: brightness(0) invert(1);
  }



  &.n3__wcag__kerning_average * {
    letter-spacing: 3px;
  }
  &.n3__wcag__kerning_large * {
    letter-spacing: 6px;
  }

  &.n3__wcag__fontsize_average *:not(.swiper-slide_content_title) {
    font-size: 1.2rem !important;
  }
  &.n3__wcag__fontsize_large *:not(.swiper-slide_content_title) {
    font-size: 1.4rem !important;
  }
}


@media(max-width:768px){
  body{
    &::-webkit-scrollbar {
      width: 10px !important;
    }
  }
 
}

.loaderG{position: fixed !important; top:0px !important; left:0px !important;  width:100vw !important; height:100vh !important; z-index: 5678 !important;   background: #123065d0 !important;
  backdrop-filter: saturate(180%) blur(10px) !important; display:flex !important; align-items: center !important; justify-content: center !important;}
.loaderG img{width:100% !important;box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset !important; border-radius:50% !important; animation-name:logo !important; animation-duration: 2s !important;animation-iteration-count: infinite !important; }

.befG{width:300px !important; border-radius:50% !important; padding:2px !important;}