.home-header {
  height: 100%;
  .swiper {
    width: 100%;
    height: 100%;
    &-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      height: 95vh;
      display: flex;
      justify-content: center;
      @media (max-width: 768px) {
          height: 500px;
      }
      &_body {
        flex: 1 1;
      }

      &_content {
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 30px;
        &_title {
          font-size: 40px;
          color: #ffffff;
          max-width: 1000px;
          @media (max-width: 768px) {
            font-size: 33px;
         }
        }
      }


    }
  }


}