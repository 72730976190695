.card{padding:10px; position: relative; transition: 0.3s; height:280px; cursor: pointer;  display: flex; border-radius: 5px; align-items: center; flex-direction: column;box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px; padding:20px 10px;}
.active{padding:10px; position: relative;  height:280px; cursor: pointer; background-color: #123065;  display: flex; border-radius: 5px; align-items: center; transition: 0.3s; flex-direction: column;box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px; padding:20px 10px;}
.icon{padding:10px; font-size: 60px; color:#123065}
.card h4{color:#123065;font-size: 22px; text-align: center;  margin-bottom: 0px !important;}
.number{padding:2px 5px; position: absolute; bottom:5px; color: #123065; font-size: 35px; font-weight: 700;}
.card:hover{background-color: #555556;}
.card:hover .icon{color:white !important}
.card:hover h4{font-size: 22px; color:white !important; text-align: center;}
.card:hover .number{color:white !important}
.active .icon{color:white !important}
.active h4{font-size: 22px; color:white !important; text-align: center; margin-bottom: 0px !important;}
.active .number{color:white !important}
.comment{box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px; padding:15px 20px 0px 20px;}
.comment img{width:40px; height:40px; border-radius: 50%;}
.comment .fio{height:100px; padding-right:10px; display: flex; align-items: center;  font-weight: bold; margin-top:-25px; color:#123065; font-size: 18px; line-height: 23px;}
.comment .fio1{height:100px;  padding-left:10px; display: flex; align-items: center;  font-weight: bold; margin-top:-25px; color:#123065; font-size: 18px; line-height: 23px;}
.text{transition: 0.5s; width:100%; word-wrap: break-word !important; font-size: 16px !important; margin-top:-25px; padding-top: 8px !important; }

.comment p{font-size: 16px; transition: 0.3s; text-align: justify; color:rgb(162, 160, 160); padding: 10px;}
.icons{display: flex; justify-content: flex-start; margin-left: -10px;}
.icons li{font-size: 25px; padding:5px; color: rgb(162, 160, 160); cursor: pointer;}
.ic{position: absolute; bottom:-20px; width:100%;}
.icT{font-size: 24px; position:relative; left:0px; transition: 0.3s;}
.icons span{transition: 0.3s; font-size: 18px;}
.icons li:hover .icT{left:15px;transform: scale(1.8, 1.8);}
.icons li:hover span{opacity: 0;}
.btn{width:calc(50% - 20px);transition: 0.3s; text-align: center; border:none; box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; font-size: 18px; letter-spacing: 1px; text-transform: uppercase; display: block; margin: auto;  background-color: #123065; color:white; padding: 11px; border-radius: 5px;}
.btn:hover{background-color: white; color:#123065}
.btn:active{box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;}
.btnR{width:95%;margin-left:5%;transition: 0.3s; text-align: center; border:none; box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; font-size: 16px; letter-spacing: 1px; text-transform: uppercase;   background-color: white; color:#123065;  padding: 9px; border-radius: 5px;}
.btnA{width:95%;margin-left:5%;transition: 0.3s; text-align: center; border:none; box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; font-size: 16px; letter-spacing: 1px; text-transform: uppercase;  background-color: #123065; color:white; padding: 9px; border-radius: 5px;}
.radios{padding:20px 0px 0px 0px; flex:50%; display: flex; justify-content: center;}
.radio{font-size: 16px; color:rgb(162, 160, 160); font-weight: bold; padding-left:20px}
.btnF{width:95%;margin-left:5%;transition: 0.3s; text-align: center; border:none; box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; font-size: 16px; letter-spacing: 1px; text-transform: uppercase;   background-color: white; color:#123065;  padding: 9px; border-radius: 5px;}
.btnG{width:95%;margin-left:5%;transition: 0.3s; text-align: center; border:none; box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px; font-size: 16px; letter-spacing: 1px; text-transform: uppercase;  background-color: #123065; color:white; padding: 9px; border-radius: 5px;}
.radiolar{display: flex; width:100%; justify-content: space-around;}
.lt{padding: 0px 0px 0px 20px;}
.colTak{padding:15px !important}
@media (min-width:1001px) and (max-width:1500px){
    .card{height:300px}
    .active{height:300px}
}
@media (max-width:1000px){
    
  .ic{position: absolute; bottom: -40px;}
  .radios{display: flex; flex-direction: column; justify-content: flex-start;}
  .radios .radio{padding-bottom: 15px;}
  .radios:nth-child(2) .radio{padding-bottom: 35px;}

}

@media (max-width:700px){
.colTak{padding:0px !important}
.comment{ padding:15px 10px 0px 10px;}
.comment img{margin-left: 10px;}
.icons{width:150% !important}
    .lt{padding:0px;}
.cl{width:100% !important;}    
  .btn{width:100%; margin-bottom: 50px;}  
  .btnF{width:100%; margin-bottom: 50px;}  
  .btnG{width:100%; margin-bottom: 50px;}  
  .radio{padding-left:10px}
  

}

.strel{transition: 0.5s; cursor:pointer}
.strel:hover{color:#123065; transform: scale(1.3,1.3);}
.text::-webkit-scrollbar {
  width: 5px;
 

}
.text::-webkit-scrollbar-track {
  background-color: rgba(243, 243, 243, 0.844);
 
}
.text::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #123065;
}