.app-footer {
    padding: 15px 20px;
    background-color: #fff;
    line-height: 24px;
    position: absolute;
    bottom:-31px;
    width:100%; 

    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    font-size: 15px;
    .footer-bottom {
        width: 80%;
        margin: 0 auto;
        padding-top: 10px;
        display: flex;
        justify-content: space-between;
        @media (max-width: 812px) {
            display: block;
            width: 95%;
            text-align: center;
        }
        a {
            color: #333333;
            display: inline-block;
            &:hover {
                text-decoration: underline;
                color: #133165;
            }
        }
    }   
}
#stat{position:relative; bottom:25px; left:75%;}
@media (max-width: 812px) {
    .app-footer a{margin-top:30px}
    #stat{bottom:60px; width:100%; display: none; justify-content: center;}
    .app-footer{bottom: 0px ;}
}