.sports_card{display: flex; border-radius: 5px; width: 100%; height: 240px;  cursor: pointer;  box-shadow: 0 2px 4px rgba(0,0,0,.4), 0 7px 13px -3px rgba(0,0,0,.3), inset 0 -3px 0 rgba(0,0,0,.2); overflow: hidden; align-items: center; justify-content: center; position: relative;}
.sports_card img{width: 100%; transition: 0.3s; transform: scale(1, 1);}
.sports_text{position: absolute;transition: 0.3s; z-index: 23; height: 60px; padding: 10px; display: flex; justify-content: center; align-items: center; background-color: rgba(255, 255, 255, 0.804); width: 100%; bottom: 0px; left:0px;}
.sports_text p{margin-bottom: 0; text-align:center; text-transform: uppercase; font-size: 18px; color: #133165; font-weight: 500;}
.sports_card:hover img{transform: scale(1.1, 1.1);}
.sports_card:hover .sports_text{background-color: #133165e8;}
.sports_card:hover .sports_text p{color: white;}
.sports .ant-row{width: 100% !important;}
.sports{margin-bottom: 90px;}
@media (min-width:1500px){
    .sports_card{height: 300px;}
}
