.docs_card img{width:50px; transform: scale(1, 1);  transition: 0.3s;}
.card_img{width:80px; transition: 0.3s; background-color: #273b7a; display: flex; align-items: center; justify-content: center !important; height: 80px; position: absolute; top:-10px; border-radius: 50%; box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;}
.row{padding:0px 15%}
.col{padding:20px}
.docs_card{position: relative;transition: 0.3s;  width:100%;box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; border-radius: 15px; height: 60px;
display: flex; align-items: center; cursor: pointer;
}
.docs_card p{margin-bottom: 0px; transition: 0.3s;  padding: 10px; color: #273b7a;}
.docs_card:hover p{color: #7faef4;}
.docs_card:hover img{transform: scale(1.1, 1.1);}
.docs_card:hover .card_img{box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;}
.row .col:nth-child(odd) .card_img{left:-40px;}
.row .col:nth-child(even) .card_img{right:-40px;}
.row .col:nth-child(odd) p{padding-left:50px;}
.row .col:nth-child(even) p{padding-right:50px; text-align: right; width: 100%; }
@media (max-width:800px){

    .row{padding-right:20px !important;padding-left:20px !important; margin-bottom: 100px; width:100% !important}
    .col{width:100% !important}
}