

.vacancy{display: flex; flex-direction: column; margin-bottom: 3vw; padding: 0vw 10vw;}
.vacancy *{transition: 0.3s;}
.vacancy_item{width: 100%; display: flex;   align-items: center; justify-content: space-between;
background-color: white; margin-top: 2vw; border-radius: 1vw; padding: 1vw;box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;}
.vacancy_item h1{margin-bottom: 0px; font-size: 1.2vw; font-weight: 700; color: #a2a0a0;}
.vacancy_item p{width: 15%; margin-bottom: 0px; display: flex; align-items: center; justify-content: flex-end; color: #a2a0a0; font-weight: 700; font-size: 1.5vw;}
.vacancy_item:hover h1{color:#133165;}
.vacancy_item:hover p{color:#133165;}
.vacancy_title{display: flex; align-items: center; justify-content: space-between; padding: 1vw;}
.vacancy_title h1{color: #133165; font-weight: 500; margin-bottom: 0;}
.vacancy_title a{color: #133165; transform: scale(1,1); font-size: 1.2vw; margin-bottom: 0;}
.vacancy_title a:hover{transform: scale(1.1, 1.1); color: #133165;}
.error_t{height: 50vh; display: flex; align-items: center; justify-content: center; color: #a2a0a0; font-weight: 700; text-transform: uppercase;}