.page-title {
    text-align: center;
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 30px;
    color:#133165;
    position: relative;
    overflow: hidden;
    & span {
        position: relative;
        &:before,
        &:after {
            content: "";
            width: 40px;
            height: 2px;
            background-color: #afaeae;
            position: absolute;
            top: 50%;
        }
        &:before {
            transform: translate(-120%, -50%);
            left: 0;
        }
        &:after {
            transform: translate(120%, -50%);
            right: 0;
        }
    }
}