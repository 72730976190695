.home-header {
  height: 100%;
  .swiper {
    width: 100%;
    height: 100%;
    &-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      height: 95vh;
      display: flex;
      justify-content: center;
      @media (max-width: 768px) {
          height: 500px;
      }
      &_body {
        flex: 1 1;
        background-size: 100%;
      }

      &_content {
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 30px;
        &_title {
          font-size: 40px;
          color: #ffffff;
          max-width: 1000px;
        
          @media (max-width: 768px) {
            font-size: 22px !important;
         }
        }
      }


    }
  }


}

.swiper-button-next, .swiper-button-prev{
 opacity:0 !important;
 width:150px !important;
 height:100vh !important;
 top:0px !important;
color:white !important; 
}

.swiper-button-next:hover{
  opacity:1 !important;
  margin-right:50px !important
  
 }
 
 .swiper-button-prev:hover{
  margin-left:50px !important;
  opacity:1 !important;
 }

 
.swiper-button-next{
  right:-50px !important;
  margin-right:50px !important
  
 }
 
 .swiper-button-prev{
  left:-50px !important;

  margin-left:50px !important;
  
 }
.open-news .swiper-button-next, .open-news .swiper-button-prev{
  height:500px !important;
 }
 .open-news .swiper-button-next::after, .open-news .swiper-button-prev::after{
   font-size:30px !important
 }