    .location {
        &-container {
            display: flex;
            justify-content: space-between;
            
            margin: 0 auto;
            margin-bottom: 100px;
            @media (max-width: 938px) {
                width: 100%;
            }
           
        }
        &-info {
            width: 30%;
            padding: 10px 0;
            a {
                padding-left: 15px;
                color: #133165;
                font-size: 17px;
            }
            .location-info-address {
                display: flex;
                // a {
                //     padding-top: 55px;
                // }
            }
            .location-info-email {
                display: flex;
                padding-top: 30px;
                .email {
                
                    font-size: 17px;
                    color: #133165;
padding-left: 15px;
                }
            }
            .location-info-phone {
                display: flex;
                padding-top: 23px;
                font-size: 17px;

            }
            .location-info-document {
                display: flex;
                padding-top: 23px;
                a {
                    font-size: 17px;
                    color: #133165;
                    padding-left: 15px;

                }
            }
            .icon {
                transition: 0.4s;
                &:hover {
                    transform: rotateY(360deg) scale(1.2);
                }
            }
            .location-info-social {
                padding-top: 25px;
                display: flex;
                gap: 15px;
                align-items: center;
                a {
                    padding: 0;
                }
                .location-info-social-item {
                    &_item {
                        color: #133165;
                        display: flex;
                        width: 33px;
                        height: 33px;
                        justify-content: center;
                        align-items: center;
                        border: 3px solid #133165;
                        border-radius: 50%;
                        transition: 0.4s;
                        &:hover {
                            transform: rotate(360deg) scale(1.2);
                            background-color: #133165;
                            color: #fff;
                        }
                    }
                }
            }
        }
        &-map {
            width: 70%;
            height: 100%;
            .map-sect {
                width: 100% !important;
                height: 100% !important;
    
            }
            
        }
        @media (max-width: 718px) {
            &-container {
                display: block;
                width: 100%;
            }
            &-info {
                width: 100%;
                padding-bottom: 40px;
            }
            &-map {
                width: 100%;
                .map-sect {
                    width: 100%;
                }
            }
        }

        .iconn {
            padding: 0;
            display: flex;
            align-items: center;
        }
    }

    .location-info-phone-nuber{padding-top: 18px !important;}