.activity-page{
    padding: 70px 0;
    .containerr{
        width: 70%;
        margin: 0 auto;
        @media (max-width: 1200px) {
            width: 100%;
            p{
                font-size: 16px;
            }
        }
    }
    .title{
        text-align: center;
        color: #133165;
        font-size: 25px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: calc(22 / 16);
    }
    .activity-img{
        width: 100%;
        padding-top: 30px;
        img{
            width: 100%;
        }
    }
    .activity-text{
        font-size: 18px;
        padding-top: 22px;
        p{
           line-height: calc(22 / 16);
           text-align: justify;
           text-indent: 50px;
        }
    }
    .location-info-social {
        padding-top: 25px;
        display: flex;
        gap: 15px;
        align-items: center;
        a {
            padding: 0;
        }
        .location-info-social-item {
            &_item {
                color: #133165;
                display: flex;
                width: 33px;
                height: 33px;
                justify-content: center;
                align-items: center;
                border: 3px solid #133165;
                border-radius: 50%;
                transition: 0.4s;
                &:hover {
                    transform: rotate(360deg) scale(1.2);
                    background-color: #133165;
                    color: #fff;
                }
            }
        }
    }
}