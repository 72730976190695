.mapR .nav, .mapR .menu {
    display: flex;
    justify-content: center;
    align-items: center;
}
.mapR .nav1{
    position: absolute; bottom:200px; left:0px;
}
.mapR .nav {
    position: relative;
    background-color: #fff;
    padding: 20px;
    height:158px;
    transition: 0.5s;
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 0 8px 15px rgba(0,0,0,.2);
}

.mapR .menu {
    margin: 0;
    padding: 0;
    width: 0;
    flex-direction: column;
    overflow: hidden;
    transition: 0.5s;
}

.mapR .nav input:checked ~ .menu {
    width: 450px;
    height:158px;
}


.mapR .menu li {
    list-style: none;
    margin: 4px 10px;
    text-decoration: none;
    color: #666;
    width:400px;
    text-transform: uppercase;
    font-weight: 600;
    transition: 0.5s;
}



.mapR .menu li a:hover {
    color: #161919;
}

.mapR .nav input {
    width: 20px;
    height: 30px;
    margin-left: -6px !important;
    cursor: pointer;
    opacity: 0;
}

.mapR .nav span {
    position: absolute;
    left: 16px;
    width: 20px;
    height: 4px;

    border-radius: 50px;
    background-color: #666;
    pointer-events: none;
    transition: 0.5s;
}

.mapR .nav input:checked ~ span {
    background-color: #133165;
}

.mapR .nav span:nth-child(2) {
    transform: translateY(-8px);
}

.mapR .nav input:checked ~ span:nth-child(2) {
    transform: translateY(0) rotate(-45deg);
}
.mapR .nav span:nth-child(3) {
    transform: translateY(8px);
}

.mapR .nav input:checked ~ span:nth-child(3) {
    transform: translateY(0) rotate(45deg);
}
@media(max-width:1700px){
    .mapR .nav1{top:450px !important; left:5px;}
    .mapR .nav input:checked ~ .menu{width:450px;}
    .mapR .menu li{width:350px; font-size:12px;}
    .mapR .nav span{width: 20px;  left:5px; height:2px}
    .mapR .nav{padding: 10px;}
    .mapR .nav1{bottom:80px}
}
@media(max-width:800px){
    .mapR .nav input:checked ~ .menu{width:350px;}
    .mapR .menu li{width:320px; font-size:12px;}
    .mapR .nav span{width: 20px;  left:5px; height:2px}
    .mapR .nav{padding: 10px;}
    .mapR .nav1{bottom:80px !important; left:0px;}
}

.modal{top:40px !important;}
@media(max-width:1700px){
.modal{top:50px !important;}

    .mapR .modal{top:140px !important;}
    
}
@media(max-width:800px){
    .modal{top:40px !important; height: 600px !important; padding:0px}
    .modal_content{padding:20px;}
    .modal_content h2{font-size:20px}
    .modal_content li{font-size:15px !important; }
.map .container{height:500px !important; padding-top: 100px !important;}
.partners{padding-top: 10px;}
}